import $ from 'jquery'
import { parse, stringify } from 'ini'

import { getConstraintButtonsHtml, getGeneralNotesHtml, getCreateConstraintsHtml, getSimpleConstraintHtml, getCountDeductionConstraintHtml } from './html_elements'

export function fullPointsButton (correctionForm) {
  const fullpoints = $(correctionForm).find('.full-points-button')
  const credits = $(correctionForm).find('#original_credits')

  $(fullpoints).on('click', () => {
    const possibleCredits = $(fullpoints).data('possible-credits')
    $(credits).val(possibleCredits)
  })
}

export function dynamicSizeAccordionItem (accordionItem) {
  const accordionbutton = $(accordionItem).find('.accordion-button')

  $(accordionbutton).one('click', () => {
    if ($(accordionbutton).attr('aria-expanded') === 'true') {
      // Sample Solution Dynamic Size
      const sampleSolution = $(accordionItem).find('.sample-solution')
      const trueHeightDivSol = $(sampleSolution).find('.true-height-div')
      if ($(trueHeightDivSol).outerHeight() < 400) {
        $(sampleSolution).css('height', 'auto')
      }
      // Answer Dynamic Size
      const answer = $(accordionItem).find('.team-answer')
      const trueHeightDivAnswer = $(answer).find('.true-height-div')
      if ($(trueHeightDivAnswer).outerHeight() < 400) {
        $(answer).css('height', 'auto')
      }
    }
  })
}

export function dynamicSizeSingleAnswer (correctionForm) {
  const answer = $(correctionForm).find('.team-answer')
  const trueHeightDivAnswer = $(answer).find('.true-height-div')
  // skip all answers in accordionsitems if they exists: height: 0
  if ($(trueHeightDivAnswer).outerHeight() !== 0 && $(trueHeightDivAnswer).outerHeight() < 400) {
    $(answer).css('height', 'auto')
  }
}

// render Final Credits field with given credits
export function renderSourceMissingDeductionResult (sourceItem, finalCredits) {
  const sourceMissingCheck = $(sourceItem).find('#source_missing')
  const inputGroup = $(sourceMissingCheck).parent().parent()
  if ($(sourceMissingCheck).length && $(inputGroup).length) {
    const finalCreditsInfo = $(inputGroup).find('.final-credits-info')
    if ($(finalCreditsInfo).length) {
      finalCreditsInfo.text(finalCredits)
    }
  }
}

export function addAdditiveFeedbackFunctionality (correctionForm) {
  const feedbackField = $(correctionForm).find('#correctors_feedback')
  $(correctionForm).find('.feedback-dropdown-item').each(function () {
    $(this).on('click', function () {
      feedbackField.val(feedbackField.val() + '\n' + $(this).text())
    })
  })
}

// calculate refreshed drop down result html once (sorted because parsed from generalFeedback (sorted))
export function getNewFeedbackAnswerDropdown (taskid) {
  let htmlString = ''
  $('#feedback-overview-' + taskid).find('.feedback-item').each(function () {
    htmlString = htmlString + '<li><button class="dropdown-item" type="button" id="dropdown-' + $(this).data('feedbackid') +
                      '" style="width:100%; white-space:pre-line">' + $(this).text() + '</button></li> <li><hr class="dropdown-divider"></li>'
  })
  return htmlString
}

export function refreshAnswerFeedback (answerDropdownMenu, newDropDownHtml) {
  $(answerDropdownMenu).html(newDropDownHtml)
  const feedbackField = $('#correction-form-' + $(answerDropdownMenu).data('answerid')).find('#correctors_feedback')
  $(answerDropdownMenu).find('.dropdown-item').each(function () {
    $(this).on('click', function () {
      feedbackField.val(feedbackField.val() + '\n' + $(this).text())
    })
  })
}

export function refreshGeneralFeedback (feedbackOverview, returnedFeedbacks) {
  const card = $(feedbackOverview).find('.card')
  if ($(card).length === 1) {
    for (const feedbackId in returnedFeedbacks) {
      const feedbackText = returnedFeedbacks[feedbackId].feedback_text
      const feedbackItem = $(card).find('#feedback-text-' + feedbackId)
      if (feedbackItem.length === 0) {
        let url = $(feedbackOverview).data('url-base')
        url = url.substring(0, url.length - 2) + '/' + feedbackId + '/'
        $(card).append('<div class="row feedback-row" id="feedback-row-' + feedbackId + '" data-feedbackid="' + feedbackId + '"> <div class="input-group flex-nowrap" id="input-group-' + feedbackId +
                       '"> <span class="input-group-text feedback-item" id="feedback-text-' + feedbackId +
                       '"style="width:100%; white-space: pre-line; text-align:left;">' + feedbackText + ' </span> <button class="btn btn-danger delete-button" id="delete-' + feedbackId +
                       '" data-url="' + url + '">Delete</button></div></div>')
      }
    }
    const list = $(card).children()
    list.sort(function (first, second) {
      const textFirst = $(first).find('.feedback-item').text()
      const textSecond = $(second).find('.feedback-item').text()
      return textFirst.localeCompare(textSecond)
    })

    $(list).each(function () {
      $(card).append($(this))
    })

    addDeleteFunctionality($(feedbackOverview))
  }
}

export function addDeleteFunctionality (feedbackOverview) {
  $(feedbackOverview).find('.delete-button').each(function () {
    $(this).off()
    $(this).on('click', () => {
      $.ajax(
        {
          type: 'GET',
          url: $(this).data('url') + '?json', // Needed to enable api functions
          data: {},
          context: $(this),
          processData: false,
          contentType: false,
          dataType: 'json',
          success: function (data) {
            if (data.success) {
              if (data.data.feedback_id) {
                console.log('Removing feedback')
                $('#feedback-row-' + data.data.feedback_id).remove()
                console.log('Removed feedback')
                const newDropDownHtml = getNewFeedbackAnswerDropdown($(feedbackOverview).data('taskid'))
                $('.feedback-dropdown-menu-' + $(feedbackOverview).data('taskid')).each(function () {
                  refreshAnswerFeedback($(this), newDropDownHtml)
                })
              }
            }
          },
        }
      )
    })
  })
}

export function loadConstraints (accordionItem) {
  const accordionButton = $(accordionItem).find('.accordion-button')

  // only once per session needed
  $(accordionButton).one('click', () => {
    // only when *opening* accordion
    if ($(accordionButton).attr('aria-expanded') === 'true') {
      // add constraints container, needed for all cases
      $(accordionItem).find('.team-answer').parent().after('<div class="container constraint-section"><div class="constraints"></div></div>')
      const constraintSection = $(accordionItem).find('.constraint-section')

      const internalComment = $(accordionItem).find('#correctors_comment')
      if ($(internalComment).val() === '') {
        // case: no content in internal comment field, request file
        const link = $('#constraints-link-' + $(accordionItem).data('taskid')).data('constraints-url')
        if (link === undefined) {
          return
        }
        $.get(link, function (file) {
          parseAndBuildConstraints(file, $(accordionItem), $(constraintSection), true)
        })
      } else {
        // case: content in internal comment field, get file from there
        const file = $(internalComment).val()
        parseAndBuildConstraints(file, $(accordionItem), $(constraintSection), false)
      }
    }
  })
}

export function parseAndBuildConstraints (file, accordionItem, constraintSection, fromFile) {
  try {
    const constraints = parse(file)
    let base = ''
    for (const [key, value] of Object.entries(constraints.constraints)) {
      if (key === 'base') {
        base = value
        if (base === 'deductive') {
          const possibleCredits = $(accordionItem).find('.full-points-button').data('possible-credits')
          $(constraintSection).find('.constraints').before('<div class="row pt-1 g3"><span class="input-group-text" style="font-weight: bold"> Starting at ' + possibleCredits + ' credits, applying the following deductions: </span></div>')
        }
      } else if (value.type === 'count_deduction') {
        let givenCredits, note, givenSteps
        if (fromFile) {
          // case: from file
          givenCredits = value.max_credits
          note = ''
          givenSteps = 0
        } else {
          // case: from internal comment
          givenCredits = value.given_credits
          note = value.note
          givenSteps = value.given_steps
        }
        buildCountDeductionConstraint($(constraintSection).find('.constraints'), key, value.type, value.deduct_steps, value.max_steps, value.constraint_text, givenSteps, givenCredits, value.max_credits, note)
      } else {
        let givenCredits, note, checked
        if (fromFile) {
          // case: from file
          givenCredits = value.max_credits
          note = ''
          checked = false
        } else {
          // case: from internal comment
          givenCredits = value.given_credits
          note = value.note
          checked = value.checked
        }
        buildSimpleConstraint($(constraintSection).find('.constraints'), key, checked, value.type, value.constraint_text, givenCredits, value.max_credits, note)
        if (key.startsWith('custom-constraint')) {
          $(constraintSection).find('.' + key).addClass('custom-constraint')
          $(constraintSection).find('.' + key).removeClass('simple-constraint')
        }
      }
    }
    addCommonConstraintFields($(accordionItem), $(constraintSection), base, constraints)
  } catch (error) {
    console.error('Error occurred while parsing file: ' + error)
  }
}

export function addCommonConstraintFields (accordionItem, constraintSection, base, constraints) {
  const constraintButtonsHtml = getConstraintButtonsHtml()
  $(constraintSection).append(constraintButtonsHtml)

  let generalNotes = ''
  if ('notes' in constraints && 'general_note' in constraints.notes) {
    generalNotes = constraints.notes.general_note
  }
  const generalNotesHtml = getGeneralNotesHtml(generalNotes)
  const correctorsCommentRow = $(accordionItem).find('#correctors_comment').parent().parent()
  $(correctorsCommentRow).after(generalNotesHtml)

  addCreateConstraintFunctionality($(accordionItem), $(constraintSection))
  addMaxConstraintFunctionality($(accordionItem), $(constraintSection), base)
  addAutoApplyFunctionality($(accordionItem), $(constraintSection), base)

  // hide correctors_comment
  $(correctorsCommentRow).hide()
}

export function addAutoApplyFunctionality (accordionItem, constraintSection, baseSaved) {
  $(constraintSection).find('input').each(function () {
    $(this).on('change', () => {
      applyConstraints($(accordionItem), $(constraintSection), baseSaved)
    })
  })
  $(constraintSection).find('.constraint-note').each(function () {
    $(this).on('focusout', () => {
      applyConstraints($(accordionItem), $(constraintSection), baseSaved)
    })
  })
  $(accordionItem).find('.general-notes-text').on('focusout', () => {
    applyConstraints($(accordionItem), $(constraintSection), baseSaved)
  })
}

export function applyConstraints (accordionItem, constraintSection, baseSaved) {
  const innerConstraints = {
    base: baseSaved,
  }
  let finalCredits = 0
  if (baseSaved === 'deductive') {
    finalCredits = parseFloat($(accordionItem).find('.full-points-button').data('possible-credits'))
  }
  // loop through simple constraints
  $(constraintSection).find('.simple-constraint').each(function () {
    finalCredits = evaluateSimpleOrBonusConstraint($(this), innerConstraints, finalCredits)
  })

  // loop through count deduction constraints
  $(constraintSection).find('.count-deduction-constraint').each(function () {
    finalCredits = evaluateCountDeductionConstraint($(this), innerConstraints, finalCredits)
  })

  // check if credits < 0: if yes, set 0
  if (finalCredits < 0) {
    finalCredits = 0
  }

  // after custom constraints, credits < 0 are and should be possible
  $(constraintSection).find('.custom-constraint').each(function () {
    finalCredits = evaluateSimpleOrBonusConstraint($(this), innerConstraints, finalCredits)
  })

  // set credits
  $(accordionItem).find('#original_credits').val(finalCredits.toString())
  // write to correctors comment
  $(accordionItem).find('#correctors_comment').val(stringify({
    constraints: innerConstraints,
    notes: {
      general_note: $(accordionItem).find('.general-notes-text').val(),
    },
  }))
}

export function evaluateSimpleOrBonusConstraint (constraint, innerConstraints, finalCredits) {
  let givenCredits = '0'
  if ($(constraint).find('.constraint-check').prop('checked')) {
    givenCredits = $(constraint).find('.constraint-credits').val()
  }
  const constraintObject = {
    type: $(constraint).find('.constraint-type').data('type'),
    checked: $(constraint).find('.constraint-check').prop('checked'),
    constraint_text: $(constraint).find('.constraint-text').text(),
    given_credits: givenCredits,
    max_credits: $(constraint).find('.constraint-max-credits').text().substring(1),
    note: $(constraint).find('.constraint-note').val(),
  }
  innerConstraints[$(constraint).data('constraint-name')] = constraintObject

  // calculate credits
  const parsedCredits = parseFloat(constraintObject.given_credits)
  if (constraintObject.type === '-') {
    finalCredits = finalCredits - parsedCredits
  } else {
    finalCredits = finalCredits + parsedCredits
  }
  return finalCredits
}

export function evaluateCountDeductionConstraint (constraint, innerConstraints, finalCredits) {
  const constraintObject = {
    type: $(constraint).find('.constraint-type').data('type'),
    constraint_text: $(constraint).find('.constraint-text').text(),
    given_credits: $(constraint).find('.constraint-credits').text(),
    given_steps: $(constraint).find('.constraint-count').val(),
    max_steps: $(constraint).find('.constraint-count').prop('max'),
    deduct_steps: $(constraint).find('.constraint-count').data('steps'),
    max_credits: $(constraint).find('.constraint-max-credits').text().substring(1),
    note: $(constraint).find('.constraint-note').val(),
  }
  innerConstraints[$(constraint).data('constraint-name')] = constraintObject

  // calculate credits
  const parsedCredits = parseFloat(constraintObject.given_credits)
  return finalCredits + parsedCredits
}

export function buildSimpleConstraint (constraintSection, key, checked, type, constraintText, givenCredits, maxCredits, note) {
  const simpleConstraintHtml = getSimpleConstraintHtml(key)
  $(constraintSection).append(simpleConstraintHtml)
  const currentConstraint = $(constraintSection).find('.' + key)

  // set check-box according to value
  $(currentConstraint).find('.constraint-check').prop('checked', checked)

  // set type: icon, color and data-type for later calculation in apply
  const constraintType = $(currentConstraint).find('.constraint-type')
  switch (type) {
    case '+':
      $(constraintType).addClass('bg-success')
      $(constraintType).append('<i class="fa-solid fa-plus"> </i>')
      $(constraintType).data('type', type)
      break
    case '-':
      $(constraintType).addClass('bg-danger')
      $(constraintType).append('<i class="fa-solid fa-minus"> </i>')
      $(constraintType).data('type', type)
      break
    case 'bonus':
      $(constraintType).addClass('bg-warning')
      $(constraintType).append('<i class="fa-solid fa-star"> </i>')
      $(constraintType).data('type', type)
      break
  }

  if (checked) {
    $(currentConstraint).find('.constraint-credits').prop('disabled', false)
    $(currentConstraint).find('.constraint-credits').val(givenCredits)
  } else {
    // if not checked, givenCredits = 0, but display full credits
    $(currentConstraint).find('.constraint-credits').val(maxCredits)
  }
  $(currentConstraint).find('.constraint-max-credits').text('/' + maxCredits)
  adaptSize($(currentConstraint).find('.constraint-type'), $(currentConstraint).find('.constraint-max-credits'))
  // set constraint-text
  $(currentConstraint).find('.constraint-text').text(constraintText)
  // set note
  const constraintNote = $(currentConstraint).find('.constraint-note')
  $(constraintNote).text(note)
  addResizableTextAreaFunctionality($(constraintNote), $(currentConstraint))
  // add check functionality
  addConstraintCheckFunctionality($(currentConstraint))
}

export function buildCountDeductionConstraint (constraintSection, key, type, deductStep, maxSteps, constraintText, givenSteps, givenCredits, maxCredits, note) {
  const countDeductionConstraintHtml = getCountDeductionConstraintHtml(key)
  $(constraintSection).append(countDeductionConstraintHtml)
  const currentConstraint = $(constraintSection).find('.' + key)

  $(currentConstraint).find('.constraint-type').data('type', type)

  const constraintCount = $(currentConstraint).find('.constraint-count')
  $(constraintCount).data('steps', deductStep)
  $(constraintCount).prop('max', maxSteps)
  $(constraintCount).val(givenSteps)

  $(currentConstraint).find('.constraint-steps').text('-' + deductStep)
  adaptSize($(currentConstraint).find('.constraint-multiply'), $(currentConstraint).find('.constraint-steps'))

  // add update functionality
  $(constraintCount).on('input', () => {
    const updatedCredits = $(constraintCount).val() * parseFloat($(constraintCount).data('steps'))
    const maxCredits = parseFloat($(currentConstraint).find('.constraint-max-credits').text().substring(1))
    if (updatedCredits <= maxCredits) {
      $(currentConstraint).find('.constraint-credits').text(maxCredits - updatedCredits)
    }
  })

  $(currentConstraint).find('.constraint-text').text(constraintText)
  $(currentConstraint).find('.constraint-credits').text(givenCredits.toString())
  $(currentConstraint).find('.constraint-max-credits').text('/' + maxCredits)

  adaptSize($(currentConstraint).find('.constraint-type'), $(currentConstraint).find('.constraint-max-credits'))
  $(currentConstraint).find('.constraint-count').outerHeight($(currentConstraint).find('.constraint-max-credits').outerHeight())

  const constraintNote = $(currentConstraint).find('.constraint-note')
  $(constraintNote).val(note)

  addResizableTextAreaFunctionality($(constraintNote), $(currentConstraint))
}

export function addResizableTextAreaFunctionality (constraintNote, currentConstraint) {
  $(constraintNote).outerHeight($(currentConstraint).find('.constraint-info-group').height())
  $(constraintNote).on('mouseup', () => {
    $(currentConstraint).find('.constraint-info-group').height($(constraintNote).outerHeight())
  })
}

export function adaptSize (targetElement, compElement) {
  $(targetElement).height($(compElement).height())
}

export function addConstraintCheckFunctionality (constraint) {
  const check = $(constraint).find('.constraint-check')
  $(check).on('click', () => {
    if ($(check).prop('checked')) {
      $(constraint).find('.constraint-credits').prop('disabled', false)
    } else {
      $(constraint).find('.constraint-credits').prop('disabled', true)
    }
  })
}

export function addCreateConstraintFunctionality (accordionItem, constraintSection, baseSaved) {
  const createConstraintButton = $(constraintSection).find('.create-constraint-button')
  $(createConstraintButton).on('click', () => {
    $(createConstraintButton).prop('disabled', true)
    const createConstraintHtml = getCreateConstraintsHtml()
    $(constraintSection).find('.constraint-buttons').after(createConstraintHtml)
    const createConstraintSection = $(accordionItem).find('.create-constraint-section')

    // dropdown functionality
    $(createConstraintSection).find('.dropdown-item').each(function () {
      $(this).on('click', () => {
        const constraintType = $(createConstraintSection).find('.constraint-type')
        $(constraintType).val($(this).text())
        $(constraintType).data('type', $(this).data('type'))
      })
    })

    $(createConstraintSection).find('.add-button').on('click', () => {
      const type = $(createConstraintSection).find('.constraint-type').data('type')
      const maxCredits = $(createConstraintSection).find('.constraint-max-credits').val()
      const constraintText = $(createConstraintSection).find('.constraint-text').val()
      const numberNewCustomConstraints = $(constraintSection).find('.custom-constraint').length + 1

      buildSimpleConstraint($(constraintSection).find('.constraints'), 'custom-constraint' + numberNewCustomConstraints.toString(), true, type, 'CUSTOM: ' + constraintText, maxCredits, maxCredits, '')
      // add custom-constraint class to new constraint to enable finding number of custom constraints later
      const customConstraint = $(constraintSection).find('.custom-constraint' + numberNewCustomConstraints.toString())
      $(customConstraint).addClass('custom-constraint')
      $(customConstraint).removeClass('simple-constraint')
      // add auto-apply to new constraint
      $(customConstraint).find('input').on('change', () => {
        applyConstraints($(accordionItem), $(constraintSection), baseSaved)
      })
      $(customConstraint).find('.constraint-note').on('focusout', () => {
        applyConstraints($(accordionItem), $(constraintSection), baseSaved)
      })

      // apply since custom constraints come pre-checked
      applyConstraints($(accordionItem), $(constraintSection), baseSaved)

      // delete addConstraint section
      $(createConstraintSection).remove()
      // add button should enable add-constraint button again
      $(createConstraintButton).prop('disabled', false)
    })

    $(createConstraintSection).find('.cancel-button').on('click', () => {
      $(createConstraintSection).remove()
      $(createConstraintButton).prop('disabled', false)
    })
  })
}

export function addMaxConstraintFunctionality (accordionItem, constraintSection, baseSaved) {
  $(constraintSection).find('.max-and-apply-button').on('click', () => {
    $(constraintSection).find('.simple-constraint').each(function () {
      if ($(this).find('.constraint-type').data('type') === '+') {
        $(this).find('.constraint-check').prop('checked', true)
        $(this).find('.constraint-credits').val($(this).find('.constraint-max-credits').text().substring(1))
        $(this).find('.constraint-credits').prop('disabled', false)
      }
    })
    $(constraintSection).find('.count-deduction-constraint').each(function () {
      $(this).find('.constraint-count').val(0)
      $(this).find('.constraint-credits').text($(this).find('.constraint-max-credits').text().substring(1))
    })
    $(constraintSection).find('.custom-constraint').each(function () {
      $(this).find('.constraint-check').prop('checked', false)
    })
    applyConstraints($(accordionItem), $(constraintSection), baseSaved)
  })
}

export function recheckConstraints (file, accordionItem) {
  try {
    const constraints = parse(file)
    for (const [key, value] of Object.entries(constraints.constraints)) {
      if (key === 'base') {
        continue
      }
      $(accordionItem).find('.' + key).find('.constraint-check').prop('checked', value.checked)
      $(accordionItem).find('.' + key).find('.constraint-credits').prop('disabled', !value.checked)
    }
  } catch (error) {
    console.error('Error occurred while parsing file: ' + error)
  }
}
