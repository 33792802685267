export function getSimpleConstraintHtml (key) {
  return '<div class="simple-constraint constraint ' + key + '" data-constraint-name="' + key + '" data-is-group="false">' +
         '<div class="row pt-1 g3">' +
         '<div class="col-8 px-0">' +
         '<div class="input-group flex-nowrap col-auto constraint-info-group">' +
         '<span class="input-group-text"><input aria-labelledby="label_' + key + '" class="form-check-input px-1 constraint-check" type="checkbox" value=""></span>' +
         '<span class="input-group-text" style="width:100%"><span id="label_' + key + '" class="px-2 constraint-text" style="white-space: pre-line; text-align: left"></span></span>' +
         '<span class="input-group-text"><span class="input-group-text constraint-type" data-type=""> </span><input aria-labelledby="label_' + key + '" class="form-control constraint-credits" type="text" pattern="[0-9]+([\\.][0-9]+)?" "value="" style="width: 60px" disabled="" required><span class="input-group-text constraint-max-credits" style="width: 67px"></span></span>' +
         '</div>' +
         '</div>' +
         '<div class="col-4 px-0">' +
         '<div style="width: 100%"><textarea aria-labelledby="label_' + key + '" class="form-control constraint-note" type="text" value="" style="height: 100%; width: 100%"> </textarea></div>' +
         '</div>' +
         '</div>' +
         '</div>'
}

export function getCountDeductionConstraintHtml (key) {
  return '<div class="count-deduction-constraint constraint ' + key + '" data-constraint-name="' + key + '" data-is-group="false">' +
         '<div class="row pt-1 g3">' +
         '<div class="col-8 px-0">' +
         '<div class="input-group flex-nowrap col-auto constraint-info-group">' +
         '<span class="input-group-text"><input aria-labelledby="label_' + key + '" class="form-check-input px-1 constraint-count" type="number" value="0" min=0 max=12 style="min-width: 50px; height:38px; text-align: center" data-steps=""><span class="input-group-text constraint-multiply"><i class="fa-solid fa-xmark"></i></span><span class="input-group-text constraint-steps"></span></span>' +
         '<span class="input-group-text" style="width: 100%"><span class="px-2 constraint-text" style="white-space: pre-line; text-align: left"></span></span>' +
         '<span class="input-group-text"><span class="input-group-text bg-success constraint-type" data-type=""> <i class="fa-solid fa-equals"></i> </span><span class="input-group-text constraint-credits" style="width:60px"></span><span class="input-group-text constraint-max-credits" style="min-width:67px"></span></span>' +
         '</div>' +
         '</div>' +
         '<div class="col-4 px-0">' +
         '<div style="width: 100%"><textarea aria-labelledby="label_' + key + '" class="form-control constraint-note" type="text" value="" style="height: 100%; width: 100%"> </textarea></div>' +
         '</div>' +
         '</div>' +
         '</div>'
}

export function getGeneralNotesHtml (notes) {
  return '<div class="general-notes">' +
         '<div class="row g3 align-items-center">' +
         '<div class="input-group flex-nowrap col-auto">' +
         '<span class="input-group-text" style="min-width: 200px">Internal Comment</span>' +
         '<textarea aria-label="Internal Comment" class="form-control general-notes-text" type="text" style="width: 100%; height: 100%">' + notes + '</textarea>' +
         '</div>' +
         '</div>' +
         '</div>'
}

export function getConstraintButtonsHtml () {
  return '<div class="constraint-buttons">' +
         '<div class="row pt-1 g3">' +
         '<div class="col-8 px-0">' +
         '<button class="btn btn-outline-success create-constraint-button" type="button">Create new constraint</button>' +
         '</div>' +
         '<div class="col-4 px-0 float-end">' +
         '<button class="btn btn-success max-and-apply-button float-end" type="button">Tick all</button>' +
         '</div>' +
         '</div>' +
         '</div>'
}

export function getCreateConstraintsHtml () {
  return '<div class="create-constraint-section">' +
         '<div class="row pt-1 g3">' +
         '<div class="card card-body">' +
         '<div class="input-group col-auto flex-nowrap">' +
         '<span class="input-group-text">Type</span><input aria-label="Type" class="constraint-type form-control" type="text" data-type="" readonly style="width:200px">' +
         '<div class="dropdown">' +
         '<button aria-label="Open Dropdown" class="btn btn-light border border-secondary-subtle dropdown-toggle" type="button" id="typeDropdownButton" data-bs-toggle="dropdown" aria-expanded="false"></button>' +
         '<ul class="dropdown-menu" aria-labelledby="typeDropdownButton">' +
         '<li><button class="dropdown-item" type="button" data-type="+">Addition</button></li>' +
         '<li><button class="dropdown-item" type="button" data-type="-">Deduction</button></li>' +
         '<li><button class="dropdown-item" type="button" data-type="bonus">Bonus</button></li>' +
         '</ul>' +
         '</div>' +
         '<span class="input-group-text">Constraint text</span><input aria-label="Constraint text" class="constraint-text form-control" type="text" style="width:100%">' +
         '<span class="input-group-text">Maximum credits</span><input aria-label="Maximum credits" class="constraint-max-credits form-control" type="number" steps="any" min=0 style="min-width:80px">' +
         '</div>' +
         '<div class="mb3 pt-1"> <button class="btn btn-danger cancel-button float-start" type="button">Cancel</button> <button class="btn btn-success add-button float-end" type="button">Add</button> </div>' +
         '</div>' +
         '</div>' +
         '</div>'
}
