import $ from 'jquery'
import Papa from 'papaparse'

// The format for grades used in the grades table and the final csv
const GRADE_NUMBER_FORMAT = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 2 })
// The format for percentages used in the grades table
const PERCENTAGE_NUMBER_FORMAT = new Intl.NumberFormat('de-DE', { style: 'percent', maximumFractionDigits: 2 })
// The date format to be used for the assessment date in the grades csv
const DATE_FORMAT = new Intl.DateTimeFormat('de-DE')

// Regex for strings representing percentages between 0 and 100, used to verify the configuration parameters
const PERCENTAGE_REGEX = /^(100|((0|1|2|3|4|5|6|7|8|9){1,2}((.|,)(0|1|2|3|4|5|6|7|8|9){1,2})?))$/

// The names of the columns the script looks for in the incomplete csv file.
// Should the names have changed, you can adjust them here.
const COL_NAME_MATR_NR = 'REGISTRATION_NUMBER'
const COL_NAME_GRADE = 'GRADE'
const COL_NAME_DATE_OF_ASSESSMENT = 'DATE_OF_ASSESSMENT'

// The id of the alerts section for the configuration
const ALERTS_CONFIG = '#alerts-configuration'
// The id of the alerts section for the grades table
const ALERTS_GRADES = '#alerts-grades'
// The id of the alerts section for the export section
const ALERTS_EXPORT = '#alerts-export'

// Alert types
const ALERT_ERROR = 'danger'
const ALERT_WARNING = 'warning'
const ALERT_SUCCESS = 'success'

// Holds an object that maps matriculation numbers to final grades.
// The calculate grades function creates this map in whenever it is called.
// Used when a csv file is uploaded to generate the complete file.
let FINAL_GRADES

// Holds the name and id for all known attestations other than the main lab
let ATTESTATIONS

// Holds the incomplete csv file in string form
let INCOMPLETE_CSV_RAW

/**
 * Enables all functionality required for the grades calculator
 *
 * @param section The div containing the grades calculator template
 */
export function setupGradesCalculator (section) {
  // Request all existing attestations and their defined weights in the config
  $.ajax({
    type: 'GET',
    url: $(section).data('attestations-url'),
    processData: false,
    contentType: false,
    dataType: 'json',
    success: function (data) {
      const tbody = $(section).find('#grade-weights-table-body')
      tbody.empty()

      const attestations = []
      data.attestations.forEach(item => {
        attestations.push({ id: item.id, name: item.name })
        createGradeWeightField(item.name, item.id, item.weight).appendTo(tbody)
      })
      ATTESTATIONS = attestations

      const mainLabWeight = data.main_lab_weight
      createGradeWeightField('Main Lab', 'main_lab', mainLabWeight).appendTo(tbody)
      const mainLabPassPercentage = data.main_lab_pass_percentage
      $(section).find('#pass-threshold').val(mainLabPassPercentage)

      // Generate the grades datatable

      // Gather all columns, starting with the user colunn
      const gradesTableColums = [
        { title: 'User' },
      ]

      // Add a column for each attestation
      ATTESTATIONS.forEach(attestation => {
        gradesTableColums.push({ title: attestation.name })
      })

      // Add the remaining columns
      gradesTableColums.push(
        { title: 'Main Lab Percentage' },
        { title: 'Main Lab Grade' },
        { title: 'Precise Final Grade' },
        { title: 'Rounded Final Grade' }
      )

      // Initialize the datatable
      const gradesTable = $(section).find('#user-course-grade-table').DataTable({
        colReorder: true,
        paging: true,
        searching: true,
        columns: gradesTableColums,
      })

      // Add the calculate grades button. We do this here because calculating grades shouldn't
      //  be possible before the page was initialized correctly.
      $(section).find('#calculate-grades-button')
        .append($('<button>')
          .attr('type', 'button')
          .addClass('btn btn-success calculate-grades-button')
          .text('Calculate Grades')
          .on('click', () => {
            calculateGrades(section, gradesTable)
          }))
    },
    error: function (xhr, status, error) {
      window.alert('Got an error requesting the data needed to initialize the page: ' + error)
    },
  })

  const bestGradeThreshold = $('#best-grade-threshold')
  const bestGradeThresholdRange = $('#best-grade-threshold-range')

  // Enable the slider for the best main lab grade credit percentage
  bestGradeThresholdRange.on('input', () => {
    bestGradeThreshold.val(bestGradeThresholdRange.val() / 2)
  })

  const gradesCsvInput = $(section).find('#grade-data-csv-input')
  $(gradesCsvInput).on('change', () => {
    // Clear any export alerts
    $(ALERTS_EXPORT).empty()

    const uploadedFile = $(gradesCsvInput).prop('files')[0]
    if (!uploadedFile) {
      return
    }

    const reader = new window.FileReader()
    reader.onload = function (event) {
      INCOMPLETE_CSV_RAW = event.target.result
      completeGradesCSV(section)
    }

    reader.onerror = function (event) {
      makeAlert(ALERTS_EXPORT, ALERT_ERROR, 'Error reading file: ' + event.target.error.message)
    }
    reader.readAsText(uploadedFile)
  })
}

/**
 * Create a table row which configures the weight of an attestation
 *
 * @param name The name of the attestation
 * @param attestationId The id of the attestation
 * @param weight The weight percentage of the attestation, e.g. 25
 * @returns The table row Jqeury html element
 */
function createGradeWeightField (name, attestationId, weight) {
  return $('<tr>')
    .append($('<td>')
      .text(name))
    .append($('<td>')
      .append($('<input>')
        .addClass('form-control grade-weight')
        .attr('type', 'number')
        .attr('step', '0.5')
        .attr('min', '0')
        .attr('max', '100')
        .attr('aria-label', 'Grade weight in percent for ' + name)
        .val(weight)
        .data('attestation', attestationId))
      .append($('<div>')
        .addClass('invalid-feedback')))
}

/**
 * Read and check the attestation weights from the table
 *
 * @param section The grades calculator div (in which the table is located)
 * @returns A map from attestation id to weight, or undefined if there was an error
 */
function checkAndGatherGradeWeights (section) {
  let totalPercentage = 0
  const tbody = $(section).find('#grade-weights-table-body')
  const weights = new Map()

  let error = false

  tbody.find('tr').each(function () {
    const inputField = $(this).find('.grade-weight')
    const weight = parsePercentage($(inputField))

    if (weight === undefined) {
      error = true
    } else {
      totalPercentage += weight
      weights.set(inputField.data('attestation'), weight)
    }
  })

  if (error) {
    return undefined
  }

  if (totalPercentage !== 100) {
    makeAlert(ALERTS_CONFIG, ALERT_ERROR, 'The grade weight percentages must add up to 100. Right now they add up to ' + totalPercentage + '.')
    return undefined
  } else {
    return weights
  }
}

/**
 * Read and check the credit threshold percentages for the main lab from the table
 *
 * @param section The grades calculator div (in which the table is located)
 * @returns An array of [percentage to pass, percentage for best grade]
 */
function checkAndGatherLabGradeThresholds (section) {
  const inputBestGrade = $(section).find('#best-grade-threshold')
  const inputPass = $(section).find('#pass-threshold')
  const percentageBestGrade = parsePercentage($(inputBestGrade))
  const percentagePass = parsePercentage($(inputPass))

  if (percentageBestGrade === undefined || percentagePass === undefined) {
    return undefined
  }

  if (percentagePass > percentageBestGrade) {
    makeAlert(ALERTS_CONFIG, ALERT_ERROR, 'The credit percentage required to achieve the best grade must not be lower than the percentage required to pass.')
    return undefined
  }

  return [percentagePass, percentageBestGrade]
}

/**
 * Parse a percentage from an input field.
 * Has to be between 0 to 100 with two optional decimal digits.
 * Must use a comma or dot as the decimal character.
 *
 * @param inputField The input field to read the percentage from
 * @returns The percentage number, or undefined if there was an error
 */
function parsePercentage (inputField) {
  const input = $(inputField).val()

  // Replace any commas with dots since parseFloat expects dots as the decimal separator
  const value = PERCENTAGE_REGEX.test(input) ? parseFloat(input.replace(',', '.')) : NaN

  let error = false
  let feedback = ''

  if (isNaN(value)) {
    error = true
    feedback = 'Not a valid percentage'
  } else if (value < 0) {
    error = true
    feedback = 'Must not be negative'
  }

  if (error) {
    inputField.addClass('is-invalid')
    inputField.removeClass('is-valid')
    $(inputField).parent().children('.invalid-feedback').text(feedback)
    return undefined
  } else {
    inputField.removeClass('is-invalid')
    inputField.addClass('is-valid')
    $(inputField).parent().children('.invalid-feedback').empty()
    return value
  }
}

/**
 * Calculate the final grades for all students and show them in the grades datatable
 *
 * @param section The div with the grade calculator template
 * @param gradesTable The grades datatable
 */
function calculateGrades (section, gradesTable) {
  clearAllAlerts()

  const gradeAsString = function (userData, attestationId) {
    if (attestationId in userData.grades) {
      return GRADE_NUMBER_FORMAT.format(userData.grades[attestationId])
    } else {
      return 'None'
    }
  }

  const gradeWeights = checkAndGatherGradeWeights(section)
  const creditPercentages = checkAndGatherLabGradeThresholds(section)

  if (gradeWeights === undefined || creditPercentages === undefined) {
    makeAlert(ALERTS_CONFIG, ALERT_ERROR, 'There is a problem with the provided parameters, please check them and try again.')
    return
  }

  const [creditPercentagePass, creditPercentageBestGrade] = creditPercentages

  $.ajax({
    type: 'GET',
    url: $(section).data('gradings-url') + '?json',
    processData: false,
    contentType: false,
    dataType: 'json',
    success: function (data) {
      // Remove any grades from the table
      gradesTable.clear()

      // Clear any existing grades
      FINAL_GRADES = {}

      // Whether no warnings or errors occurred while calculating the grades for all students
      let flawless = true

      data.gradings.forEach(item => {
        const userGrades = calculateGradeForUser(item, gradeWeights, creditPercentagePass, creditPercentageBestGrade)

        const row = [
          item.user,
        ]

        // Show the user's attestation grades in the table for context
        ATTESTATIONS.forEach(attestation => {
          row.push(gradeAsString(item, attestation.id))
        })

        if ('error' in userGrades) {
          // The student's final grade could not be calculated, show the given error message instead
          makeAlert(ALERTS_GRADES, ALERT_WARNING, 'Error calculating grade for student with matriculation number ' + item.matriculation_number +
            '. This student will not receive any grade. Error message: ' + userGrades.error)

          // We got an error
          flawless = false

          // Add empty fields
          row.push(
            '--', // Main Lab Percentage
            '--', // Main Lab Grade
            '--', // Precise Final Grade
            '--' // Rounded Final Grade
          )
          gradesTable.row.add(row)
          return
        }

        const preciseFinalGrade = userGrades.final_grade
        const roundedFinalGrade = roundGradeToNextBest(preciseFinalGrade)

        FINAL_GRADES[item.matriculation_number] = roundedFinalGrade

        row.push(
          PERCENTAGE_NUMBER_FORMAT.format(userGrades.main_lab_percentage),
          GRADE_NUMBER_FORMAT.format(userGrades.main_lab_grade),
          GRADE_NUMBER_FORMAT.format(preciseFinalGrade),
          '<b>' + GRADE_NUMBER_FORMAT.format(roundedFinalGrade) + '</b>'
        )

        gradesTable.row.add(row)
      })

      // Adjust column widths based on the inserted data and draw the updated table
      gradesTable.columns.adjust().draw()

      if (flawless) {
        makeAlert(ALERTS_GRADES, ALERT_SUCCESS, 'Successfully calculated all grades.')
      } else {
        makeAlert(ALERTS_GRADES, ALERT_WARNING, 'Calculated the final grades with at least one warning or error.')
      }

      // If the incomplete csv file was already uploaded, update the download of the complete file to contain the calculated grades
      completeGradesCSV(section)
    },
    error: function (xhr, status, error) {
      makeAlert(ALERTS_GRADES, ALERT_ERROR, 'Got status' + status + ' after trying to retrieve the students\' grades: ' + error)
    },
  })
}

/**
 * Calculate a main lab grade.
 *
 * @param achievedCredits The number of achieved credits
 * @param creditsToPass The number of credits required to pass the main lab
 * @param creditsForBestGrade The number of credits required to achieve the best grade
 * @returns The resulting grade. Yields 5.0 if credits insufficient to pass.
 */
function calculateMainLabGrade (achievedCredits, creditsToPass, creditsForBestGrade) {
  if (achievedCredits < creditsToPass) {
    return 5.0
  }
  if (achievedCredits >= creditsForBestGrade) {
    return 1.0
  }
  return 4.0 - ((3.0 * achievedCredits - 3.0 * creditsToPass) / (creditsForBestGrade - creditsToPass))
}

/**
 * Round a precise decimal grade to the next best grade step.
 * For example, 2.299 rounds to 2.0 and 4.1 rounds to  4.0 (!).
 *
 * Note that there is no exception for grades below 4.0, grades between 4.0 and 4.3 (exclusive) are rounded to 4.0.
 * Therefore, check whether a student has passed before rounding the grade!
 *
 * @param preciseGrade The precise grade.
 * @returns The rounded grade.
 */
function roundGradeToNextBest (preciseGrade) {
  let grade = 1.0
  const steps = [1.3, 1.7, 2.0, 2.3, 2.7, 3.0, 3.3, 3.7, 4.0, 4.3, 4.7, 5.0]
  steps.forEach(function (step, index, arr) {
    if (preciseGrade >= step) {
      grade = step
    }
  })
  return grade
}

/**
 * Calculate a student's final grade for the course.
 *
 * @param userGradeData An object holding the data needed.
 * @param gradeWeights The map with the attestation weights
 * @param mainLabPercentagePass The percentage of credits required to pass the main lab
 * @param mainLabPercentageBestGrade The percentage of credits required to achieve the best grade
 * @returns An object with the final grade and other information or, if there was a problem, an object with an error message.
 */
function calculateGradeForUser (userGradeData, gradeWeights, mainLabPercentagePass, mainLabPercentageBestGrade) {
  const resultError = function (message) {
    return {
      error: message,
    }
  }

  const resultSuccess = function (mainLabPercentage, mainLabGrade, finalGrade) {
    return {
      main_lab_percentage: mainLabPercentage,
      main_lab_grade: mainLabGrade,
      final_grade: finalGrade,
    }
  }

  let finalGrade = 0
  let weightSum = 0 // Used to verify the total weight

  const creditsAchieved = userGradeData.mainlab_credits_achieved
  const creditsPossible = userGradeData.mainlab_credits_possible

  if (!creditsPossible > 0) {
    // The student did not participate in any main lab. We consider them as failed and print a warning to point that out.
    makeAlert(ALERTS_GRADES, ALERT_WARNING, 'The student with matriculation number ' + userGradeData.matriculation_number + ' did not participate in any main lab.' +
      ' They will receive the grade 5.0 for the course.')
    return resultSuccess(0.0, 5.0, 5.0)
  }

  // Calculate the main lab grade
  const creditsToPass = creditsPossible * mainLabPercentagePass / 100
  const creditsForBestGrade = creditsPossible * mainLabPercentageBestGrade / 100
  const mainLabGrade = calculateMainLabGrade(creditsAchieved, creditsToPass, creditsForBestGrade)
  const mainLabWeight = gradeWeights.get('main_lab')
  const mainLabPercentage = creditsAchieved / creditsPossible

  if (mainLabGrade > 4.0) {
    // Main lab failed, ignore any attestation grades
    return resultSuccess(mainLabPercentage, mainLabGrade, 5.0)
  }

  finalGrade += mainLabGrade * mainLabWeight / 100
  weightSum += mainLabWeight

  // List of the names of all attestations for which the user is missing a grade
  // Used to return a user-friendly error in case any grades are missing
  const missingGrades = []

  // Whether the student has failed any of the additional attestations
  let attestationFailed = false

  // Iterate over all known attestations, excluding the main lab
  ATTESTATIONS.forEach(attestation => {
    if (attestation.id in userGradeData.grades) {
      const grade = userGradeData.grades[attestation.id]

      if (grade > 4.0) {
        attestationFailed = true
      }

      const weight = gradeWeights.get(attestation.id)
      finalGrade += grade * weight / 100
      weightSum += weight
    } else {
      // The user does not have a grade for this attestation. We will return an error later.
      missingGrades.push(attestation.name)
    }
  })

  if (attestationFailed) {
    // The student has failed at least one of the additional attestations
    // Missing grades do not matter in this case
    return resultSuccess(mainLabPercentage, mainLabGrade, 5.0)
  }

  if (missingGrades.length > 0) {
    // The student is missing a grade. We consider them as failed and print a warning to point that out.
    makeAlert(ALERTS_GRADES, ALERT_WARNING, 'The student with the matriculation number ' + userGradeData.matriculation_number + ' received the grade 5.0 because' +
      ' they are missing a grade for the attestation(s): ' + missingGrades)
    return resultSuccess(mainLabPercentage, mainLabGrade, 5.0)
  }

  // Sanity check
  if (weightSum !== 100) {
    return resultError('The sum of grade weights is ' + weightSum + ' instead of 100. Check the configuration parameters and try reloading the page.')
  }

  return resultSuccess(mainLabPercentage, mainLabGrade, finalGrade)
}

/**
 * Fill in the incomplete grades csv.
 *
 * @param section The grades calculator div.
 */
function completeGradesCSV (section) {
  if (!FINAL_GRADES) {
    // No grades were calculated yet
    return
  }

  if (!INCOMPLETE_CSV_RAW) {
    // No incomplete csv file was uploaded yet
    return
  }

  // Parse the raw csv string
  const result = Papa.parse(INCOMPLETE_CSV_RAW, { delimiter: ';', skipEmptyLines: 'greedy' })
  if (result.errors.length > 0) {
    for (const err in result.errors) {
      makeAlert(ALERTS_EXPORT, ALERT_ERROR, 'Got error parsing CSV in row ' + err.row + ': ' + err.message)
    }
    return
  }

  const data = result.data
  if (!data) {
    makeAlert(ALERTS_EXPORT, ALERT_ERROR, 'Could not parse the provided grades CSV file. Please check if you have actually submitted the correct file and try again.')
    return
  }

  if (data.length < 1) {
    // No data
    makeAlert(ALERTS_EXPORT, ALERT_WARNING, 'The CSV file was empty.')
    return
  }

  let columnMatriculationNumber = -1
  let columnGrade = -1
  let columnDateOfAssessment = -1

  const columnCount = data[0].length

  // Find the indices of the columns for the matriculation number, grade and date of assessment
  $.each(data[0], function (index, value) {
    if (value.toUpperCase() === COL_NAME_MATR_NR) {
      columnMatriculationNumber = index
    } else if (value.toUpperCase() === COL_NAME_GRADE) {
      columnGrade = index
    } else if (value.toUpperCase() === COL_NAME_DATE_OF_ASSESSMENT) {
      columnDateOfAssessment = index
    }
  })

  // Error if any of the columns could not be found
  if (columnMatriculationNumber < 0 || columnGrade < 0 || columnDateOfAssessment < 0) {
    makeAlert(ALERTS_EXPORT, ALERT_ERROR, 'Could not recognize the format of the provided grades CSV file. Did the format change? Aborting.')
    return
  }

  // Parse the provided date of assessment
  const dateOfAssessmentInput = $(section).find('#grade-input-date-of-assessment')
  const numericDateOfAssessment = Date.parse(dateOfAssessmentInput.val())
  if (isNaN(numericDateOfAssessment)) {
    dateOfAssessmentInput.addClass('is-invalid')
    dateOfAssessmentInput.removeClass('is-valid')
    $(dateOfAssessmentInput).parent().children('.invalid-feedback').text('Not a valid date')
    makeAlert(ALERTS_EXPORT, ALERT_ERROR, 'Please provide a valid date of assessment and then try uploading the CSV file again or recalculating the grades.')
    return
  } else {
    dateOfAssessmentInput.addClass('is-valid')
    dateOfAssessmentInput.removeClass('is-invalid')
    $(dateOfAssessmentInput).parent().children('.invalid-feedback').empty()
  }
  const dateOfAssessment = DATE_FORMAT.format(numericDateOfAssessment)

  // An object to keep track of all matriculation numbers found in the csv file
  // Used to give a friendly error in case of missing students or duplicates
  const seenStudents = {}

  // Whether no warnings occurred when filling in the csv file
  let flawless = true

  // Iterate over all rows expecpt the header and fill in the missing values
  for (let i = 1; i < data.length; i++) {
    const row = data[i]
    if (row.length !== columnCount) {
      makeAlert(ALERTS_EXPORT, ALERT_ERROR, 'The provided grades CSV file has a varying amount of columns. Aborting.')
      return
    }

    const matriculationNumber = row[columnMatriculationNumber]

    if (matriculationNumber in seenStudents) {
      // We have already seen an entry with this matriculation number
      makeAlert(ALERTS_EXPORT, ALERT_WARNING, 'The provided CSV file has a duplicate entry for a student with the matriculation number ' +
        matriculationNumber + '. Filled in the grade there as well.')

      // We got a warning
      flawless = false

      // Not aborting here, we fill in the duplicate entry as well for consistency
    }

    seenStudents[matriculationNumber] = {} // Mark the student as seen

    const finalGrade = FINAL_GRADES[matriculationNumber]

    if (finalGrade === undefined) {
      makeAlert(ALERTS_EXPORT, ALERT_WARNING, 'The provided CSV file has an entry for a student with matriculation number ' + matriculationNumber +
        ' but there is no grade for them. The entry for this student was left unchanged. Was there an error calculating their grade?' +
        ' Is there actually a student with this matriculation number registered in the labsystem?')

      // We got a warning
      flawless = false
      continue
    }

    data[i][columnGrade] = GRADE_NUMBER_FORMAT.format(finalGrade) // Fix to 1 decimal digit so that for example 1.0 is displayed as '1.0' instead of just '1'
    data[i][columnDateOfAssessment] = dateOfAssessment
  }

  // Iterate over all calculated student grades to check if any of the students was not in the file
  // Does not error for missing students if there also was an error calculating the grade for that student
  $.each(FINAL_GRADES, function (matriculationNumber, _) {
    if (!(matriculationNumber in seenStudents)) {
      // We have a grade for this student but did not see them in the csv file.
      makeAlert(ALERTS_EXPORT, ALERT_WARNING, 'There is a grade for a student with the matriculation number ' + matriculationNumber + ' but the provided CSV' +
        ' file does not have an entry for them. The grade was not added to the CSV file.')

      // We got a warning
      flawless = false
    }
  })

  // Convert the changed data back to a csv string
  const stringified = Papa.unparse(data, { delimiter: ';' })

  // Find the div where we want to insert the download button
  const downloadButtonSection = $(section).find('#download-complete-grades-csv')

  // Remove the outdated csv download
  $(downloadButtonSection).find('#button-download-complete-grades-csv').detach()

  // Add the download button for the new csv file
  $('<a>')
    .addClass('btn btn-primary')
    .attr('id', 'button-download-complete-grades-csv')
    .attr('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(stringified))
    .attr('download', 'grades.csv')
    .attr('role', 'button')
    .text('Download CSV')
    .appendTo($(downloadButtonSection))

  if (flawless) {
    makeAlert(ALERTS_EXPORT, ALERT_SUCCESS, 'Successfully generated a complete grades CSV file. You can download it below.')
  } else {
    makeAlert(ALERTS_EXPORT, ALERT_WARNING, 'Generated a complete CSV file with at least one warning or error. You can download it below.')
  }
}

/**
 * Create an alert of the specified type in the given section.
 *
 * @param where The jquery object or id of the section to add the alert to.
 * @param type The type of the alert, e.g. danger, warning or success.
 * @param message The message to display.
 */
function makeAlert (where, type, message) {
  $(where)
    .append($('<div>')
      .addClass('alert alert-' + type + ' d-flex align-items-center alert-dismissible fade show')
      .attr('role', 'alert')
      .append($('<div>')
        .text(message))
      .append($('<button>')
        .attr('type', 'button')
        .addClass('btn-close')
        .data('bs-dismiss', 'alert')
        .attr('aria-label', 'Close')))
}

/**
 * Clear all alerts on the page.
 */
function clearAllAlerts () {
  $('#alerts').empty()
  $(ALERTS_CONFIG).empty()
  $(ALERTS_GRADES).empty()
  $(ALERTS_EXPORT).empty()
}
